.page {
  min-height: 100vh;
  background: $bg-light;
}

.divider {
  height: 1px;
  width: 100%;
  border-top: 1px solid rgba(231, 234, 243, 0.7);
  margin: 24px 0;
}
