.control {
  display: inline-flex;
  position: relative;
  color: $gray;
  align-items: center;
  overflow: hidden;
  &__icon {
    margin-right: 10px;
    .la {
      display: block;
      font-size: 22px;
    }
  }
  &__title {
    font-weight: 700;
  }
  &__file {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    font-size: 20px;
    opacity: 0;
    cursor: pointer;
  }
  &__input {
    width: 100%;
    height: 21px;
  }
}
