.sidebar {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background: white;
  z-index: 59;
  @include m {
    position: static;
    height: auto;
    background: none;
  }
  &__nav {
    position: relative;
    width: 84px;
    z-index: 2;
    @include m {
      width: 0;
      z-index: 59;
    }
  }
  &__container {
    display: flex;
    width: 386px;
    border-right: 1px solid $border;
    flex-direction: column;
    @include l {
      display: none;
      &.toggled {
        display: flex !important;
      }
    }
    @include m {
      display: none;
      width: 100%;
    }
    &.toggled {
      display: none;
    }
  }
  &__container.toggled &__action_toggle {
    @include m {
      .la {
        transform: rotate(90deg);
      }
    }
  }
  &__container.toggled &__inner {
    @include m {
      display: block;
    }
  }
  &__head {
    display: flex;
    padding: 24px 28px;
    border-bottom: 1px solid $border;
    align-items: center;
    @include m {
      padding: 16px;
      background: white;
    }
  }
  &__preview {
    display: flex;
    height: 36px;
    margin-right: 12px;
    background: $bg-light;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    flex: 0 0 36px;
  }
  &__pic {
    border-radius: 6px;
  }
  &__wrap {
    flex: 1 1 auto;
  }
  &__action {
    margin-left: 16px;
    &_toggle {
      display: none;
      @include l {
        display: block;
      }
      .la {
        @include m {
          transform: rotate(-90deg);
        }
      }
    }
  }
  &__title {
    font-weight: 700;
  }
  &__text {
    font-size: 12px;
    color: $gray;
  }
  &__inner {
    display: flex;
    padding: 28px;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: auto;
    @include m {
      display: none;
      padding: 16px;
      overflow: visible;
    }
  }
  &__section {
    margin-bottom: 28px;
    &:last-child {
      margin: auto 0 0;
    }
  }

  &_info {
    @include m {
      margin-bottom: -69px;
    }
  }
  &_info &__container {
    @include m {
      display: block;
      padding-top: 69px;
    }
  }

  &_menu &__head {
    @include m {
      display: none;
    }
  }
  &_menu &__inner {
    @include m {
      display: block;
    }
  }
}
