.container {
  padding-left: 84px;
  @include l {
    padding-left: 84px;
  }
  @include m {
    padding: 69px 0 0;
  }
  &__head {
    display: flex;
    padding: 24px 28px;
    height: 64px;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: $bg-light; //f5f5fa
    border-bottom: 1px solid rgb(129 129 165 / 12%);
    z-index: 999999;
    margin-bottom: 30px;

    @include m {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 16px;
      background: white;
      border-bottom: 1px solid $border;
      justify-content: space-between;
    }
  }
  &__action_menu {
    margin-right: 20px;
    @include m {
      display: none;
    }
  }
  &__title {
    @include m {
      font-size: 18px;
      order: 2;
    }
  }
  &__search {
    margin-left: auto;
    @include m {
      margin: 0;
      order: 1;
    }
  }
  &__new {
    margin-left: 6px;
    @include m {
      margin: 0;
      order: 3;
    }
  }
  &__body {
    padding: 0 28px 28px;
    @include m {
      padding: 16px 16px 92px;
    }
  }

  &.extended {
    padding-left: 84px;
    @include ml {
      padding-left: 242px;
    }
    @include m {
      padding: 69px 0 0;
    }
  }
}
