.online {
  position: absolute;
  right: -5px;
  bottom: -4px;
  width: 12px;
  height: 12px;
  background: $green;
  border: 2px solid white;
  border-radius: 100%;
}

.offline {
  position: absolute;
  right: -5px;
  bottom: -4px;
  width: 12px;
  height: 12px;
  background: $gray;
  border: 2px solid white;
  border-radius: 100%;
}
