.series {
  display: flex;
  margin: 0 -24px -12px;
  flex-wrap: wrap;
  @include m {
    display: block;
  }
  &__item {
    display: flex;
    margin: 0 24px 12px;
    flex: 1 1 calc(50% - 48px);
  }
  &__circle {
    position: relative;
    width: 12px;
    height: 12px;
    margin: 4px 10px 0 0;
    border-radius: 50%;
    flex: 0 0 12px;
    &:after {
      content: '';
      position: absolute;
      top: 4px;
      left: 4px;
      width: 4px;
      height: 4px;
      background: white;
      border-radius: 50%;
    }
  }
  &__title {
    margin-right: 16px;
  }
  &__value {
    margin-left: auto;
    font-weight: 700;
  }

  &_lines {
    display: block;
  }
}
