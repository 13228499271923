.tag {
  display: inline-flex;
  min-width: 98px;
  padding: 6px 15px 7px;
  border: 1px solid transparent;
  border-radius: 8px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  @include m {
    min-width: 0;
    padding: 6px 8px 7px;
  }
  &_stroke {
    background: white;
    border-color: $border;
  }
  .la {
    position: relative;
    top: -1px;
    margin-right: 8px;
    color: $gray;
  }
  &_dense {
    min-width: 80px;
    padding: 4px 5px;
    font-size: 0.7em;
  }
}

.tag-group {
  display: flex;
  margin: 0 -4px -4px 0;
  flex-wrap: wrap;
  .tag {
    margin: 0 4px 4px 0;
  }
}

.tag_status {
  $tag_status_size: 10px;
  $top: $tag_status_size / 2;

  display: inline-block;
  position: relative;
  font-size: 0.8em;
  line-height: 0.8em;
  font-weight: 300;
  color: $gray;

  &::after {
    position: absolute;
    left: -($tag_status_size * 1.5);
    top: calc((50%) - 5px);
    height: $tag_status_size;
    width: $tag_status_size;
    background-color: $rangeTrackBg;
    border-radius: $tag_status_size;
    content: '';
  }

  &.new ::after {
    background-color: $blue;
  }

  &.active ::after {
    background-color: $yellow;
  }

  &.complete ::after {
    background-color: $green;
  }
}
