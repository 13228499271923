.reply {
  display: flex;
  background: $bg-light;
  border-radius: 12px;
  &__field {
    flex: 1 1 auto;
  }
  &__textarea {
    padding: 27px 30px;
    @include m {
      padding: 27px 16px;
    }
  }
  &__actions {
    display: flex;
    padding: 18px 28px 18px 8px;
    align-items: center;
    @include m {
      padding-right: 16px;
    }
  }
  &__action {
    width: 24px;
    height: 24px;
    color: $gray;
    transition: color 0.2s;
    &:hover {
      color: $cl;
    }
    .la {
      vertical-align: middle;
    }
  }
  &__btn {
    height: 36px;
    margin-left: 8px;
  }
}
