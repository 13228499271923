.select2 {
  &-container {
    display: block;
    //width: 100%;
    z-index: 999998;
  }
  &-container-modal {
    z-index: 999999;
  }
  &-container &-selection--single {
    height: 54px;
    border-color: $border;
    border-width: 0 0 2px;
    border-radius: 0;
  }
  &-container--default &-selection--single &-selection__rendered {
    padding: 0 24px 0 0;
    font-weight: 700;
    line-height: 54px;
    color: #1c1d21;
    font-size: 16px;
  }
  &-container--default &-selection--single &-selection__placeholder {
    color: $gray;
  }
  &-selection__placeholder {
    color: $gray;
    opacity: 0.7;
    font-weight: 400;
  }
  &-container--default &-search--dropdown &-search__field {
    height: 46px;
    padding: 0 16px;
    border: none;
    border-radius: 7px;
  }
  &-container--default &-results__option[aria-selected='true'] {
    background: $bg-light;
  }
  &-container--default &-results__option--highlighted[aria-selected] {
    background: $blue;
  }
  &-container--open &-dropdown--above {
    border: 1px solid $border;
  }
  &-container--default &-selection--multiple {
    border: none;
  }
  &-container &-selection--multiple {
    min-height: 54px;
    border-bottom: 1px solid $border;
    border-radius: 0;
  }
  &-container--default#{&}-container--focus &-selection--multiple {
    border: none;
  }
  &-container--default &-selection--multiple &-selection__rendered {
    padding: 10px 20px 0 0;
  }
  &-container--default &-selection--multiple &-selection__choice {
    padding: 4px 8px;
    background: $bg-light;
    border: 1px solid $border;
    border-radius: 2px;
    font-weight: 700;
  }
  &-container--default &-selection--multiple &-selection__choice__remove {
    margin-right: 6px;
  }

  &-selection {
    &__arrow {
      display: none;
    }
  }

  &-dropdown {
    border-radius: 8px;
    border-color: $border;
    box-shadow: 0px 6px 20px rgba(153, 155, 168, 0.1);
  }
  &-dropdown--below &-results__option {
    &:last-child {
      border-radius: 0 0 7px 7px;
    }
  }

  &-results__option {
    padding: 12px 16px;
    font-weight: 700;
  }

  &-search--dropdown {
    padding: 0;
    border-bottom: 1px solid $border;
    //box-shadow: inset 0 1px 3px rgba(0,0,0,.06), 0 0 5px rgba(94,129,244,.7);
  }

  &-search__field {
    height: 31px;
  }

  &-icon-option {
    display: flex;
    position: relative;
    .la {
      margin-right: 12px;
    }

    .indicator {
      display: inline-block;
      position: absolute;
      width: 10px;
      height: 10px;
      top: 50%;
      margin-top: -5px;
      right: 0px;
      background-color: var(--blue-light);
      border-radius: 50%;
    }
  }
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background: #f5f5fa;
  border: 1px solid #f5f5fa;
  border-radius: 20px;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__clear,
.select2-container--default
  .select2-selection--single
  .select2-selection__clear {
  font-weight: 400;
  background: #f5f5fa;
  padding: 3px;
  display: inline-block;
  height: 20px;
  line-height: 0.8;
  width: 20px;
  text-align: center;
  border-radius: 20px;
  color: #767696;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__clear {
  margin-top: 16px;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #8181a5;
  font-weight: 400;
}

.select2-container--open .select2-selection--single {
  border-color: $blue;
}
