/*! system-font.css v2.0.2 | CC0-1.0 License | github.com/jonathantneal/system-font-css */

@font-face {
  font-family: system-ui;
  font-style: normal;
  font-weight: 300;
  src: local('.SFNS-Light'), local('.SFNSText-Light'),
    local('.HelveticaNeueDeskInterface-Light'), local('.LucidaGrandeUI'),
    local('Segoe UI Light'), local('Ubuntu Light'), local('Roboto-Light'),
    local('DroidSans'), local('Tahoma');
}

@font-face {
  font-family: system-ui;
  font-style: italic;
  font-weight: 300;
  src: local('.SFNS-LightItalic'), local('.SFNSText-LightItalic'),
    local('.HelveticaNeueDeskInterface-Italic'), local('.LucidaGrandeUI'),
    local('Segoe UI Light Italic'), local('Ubuntu Light Italic'),
    local('Roboto-LightItalic'), local('DroidSans'), local('Tahoma');
}

@font-face {
  font-family: system-ui;
  font-style: normal;
  font-weight: 400;
  src: local('.SFNS-Regular'), local('.SFNSText-Regular'),
    local('.HelveticaNeueDeskInterface-Regular'), local('.LucidaGrandeUI'),
    local('Segoe UI'), local('Ubuntu'), local('Roboto-Regular'),
    local('DroidSans'), local('Tahoma');
}

@font-face {
  font-family: system-ui;
  font-style: italic;
  font-weight: 400;
  src: local('.SFNS-Italic'), local('.SFNSText-Italic'),
    local('.HelveticaNeueDeskInterface-Italic'), local('.LucidaGrandeUI'),
    local('Segoe UI Italic'), local('Ubuntu Italic'), local('Roboto-Italic'),
    local('DroidSans'), local('Tahoma');
}

@font-face {
  font-family: system-ui;
  font-style: normal;
  font-weight: 500;
  src: local('.SFNS-Medium'), local('.SFNSText-Medium'),
    local('.HelveticaNeueDeskInterface-MediumP4'), local('.LucidaGrandeUI'),
    local('Segoe UI Semibold'), local('Ubuntu Medium'), local('Roboto-Medium'),
    local('DroidSans-Bold'), local('Tahoma Bold');
}

@font-face {
  font-family: system-ui;
  font-style: italic;
  font-weight: 500;
  src: local('.SFNS-MediumItalic'), local('.SFNSText-MediumItalic'),
    local('.HelveticaNeueDeskInterface-MediumItalicP4'),
    local('.LucidaGrandeUI'), local('Segoe UI Semibold Italic'),
    local('Ubuntu Medium Italic'), local('Roboto-MediumItalic'),
    local('DroidSans-Bold'), local('Tahoma Bold');
}

@font-face {
  font-family: system-ui;
  font-style: normal;
  font-weight: 700;
  src: local('.SFNS-Bold'), local('.SFNSText-Bold'),
    local('.HelveticaNeueDeskInterface-Bold'), local('.LucidaGrandeUI'),
    local('Segoe UI Bold'), local('Ubuntu Bold'), local('Roboto-Bold'),
    local('DroidSans-Bold'), local('Tahoma Bold');
}

@font-face {
  font-family: system-ui;
  font-style: italic;
  font-weight: 700;
  src: local('.SFNS-BoldItalic'), local('.SFNSText-BoldItalic'),
    local('.HelveticaNeueDeskInterface-BoldItalic'), local('.LucidaGrandeUI'),
    local('Segoe UI Bold Italic'), local('Ubuntu Bold Italic'),
    local('Roboto-BoldItalic'), local('DroidSans-Bold'), local('Tahoma Bold');
}

//

/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Product Sans Regular'),
    url('/assets/fonts/ps/ProductSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Product Sans Italic'),
    url('/assets/fonts/ps/ProductSans-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 200;
  src: local('Product Sans Thin Regular'),
    url('/assets/fonts/ps/ProductSans-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Product Sans Light Regular'),
    url('/assets/fonts/ps/ProductSans-Light.woff') format('woff');
}

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 500;
  src: local('Product Sans Medium Regular'),
    url('/assets/fonts/ps/ProductSans-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 900;
  src: local('Product Sans Black Regular'),
    url('/assets/fonts/ps/ProductSans-Black.woff') format('woff');
}

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 200;
  src: local('Product Sans Thin Italic'),
    url('/assets/fonts/ps/ProductSans-ThinItalic.woff') format('woff');
}

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 300;
  src: local('Product Sans Light Italic'),
    url('/assets/fonts/ps/ProductSans-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 500;
  src: local('Product Sans Medium Italic'),
    url('/assets/fonts/ps/ProductSans-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Product Sans Bold'),
    url('/assets/fonts/ps/ProductSans-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 700;
  src: local('Product Sans Bold Italic'),
    url('/assets/fonts/ps/ProductSans-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 900;
  src: local('Product Sans Black Italic'),
    url('/assets/fonts/ps/ProductSans-BlackItalic.woff') format('woff');
}
