.auth {
  display: flex;
  position: relative;
  @include t {
    min-height: 100vh;
    flex-direction: column;
  }
  &__main {
    display: none;
    padding: 100px;
    max-height: 500px;
    flex: 1 1 auto;
    @include cover;
    @include t {
      display: flex;
    }
    @include m {
      padding: 116px 32px;
    }
  }
  &__main &__title {
    margin: 0;
  }
  &__wrap {
    margin: auto;
    color: white;
    text-align: center;
  }
  &__preview {
    margin-bottom: 14px;
    font-size: 0;
  }
  &__pic {
    display: inline-block;
  }
  &__subtitle {
    display: none;
    @include m {
      display: block;
    }
  }
  &__text {
    color: $gray;
  }
  &__container {
    display: flex;
    position: relative;
    min-height: 100vh;
    background: white;
    border-radius: 0 16px 16px 0;
    flex: 0 0 55.68%;
    z-index: 2;
    @include t {
      min-height: 0;
      width: 100%;
      margin-top: -20px;
      border-radius: 16px 16px 0 0;
      flex: auto;
    }
  }
  &__inner {
    width: 100%;
    max-width: 700px;
    margin: auto 0 auto auto;
    padding: 60px 100px;
    @include d {
      padding-right: 60px;
    }
    @include t {
      max-width: 500px;
      margin: 0 auto;
      padding: 60px;
    }
    @include m {
      padding: 32px;
    }
  }
  &__head {
    margin-bottom: 62px;
    @include m {
      display: none;
    }
  }
  &__title {
    margin-bottom: 12px;
  }
  &__form {
    max-width: 360px;
    @include t {
      max-width: 100%;
    }
  }
  &__field {
    margin-bottom: 24px;
    @include m {
      margin-bottom: 16px;
    }
  }
  &__flex {
    display: flex;
    margin-bottom: 38px;
    align-items: center;
    justify-content: space-between;
    @include m {
      margin-bottom: 24px;
    }
  }
  &__link {
    font-weight: 700;
  }
  &__btns {
    display: flex;
    margin: 38px -5px 0;
    @include m {
      margin-top: 24px;
    }
  }
  &__btn {
    margin: 0 5px;
    flex: 0 0 calc(50% - 10px);
  }
  &__btn-full {
    margin: 0 5px;
    flex: 0 0 100%;
  }
  &__enter {
    margin-top: 76px;
    @include m {
      margin-top: 24px;
    }
  }
  &__bg {
    margin-left: -20px;
    @include cover;
    flex: 0 0 calc(44.32% + 20px);
    @include t {
      display: none;
    }
  }
}
