.empty {
  display: flex;
  min-height: calc(100vh - 112px);
  @include m {
    min-height: calc(100vh - 188px);
  }
  &__container {
    width: 100%;
    max-width: 540px;
    margin: auto;
    text-align: center;
  }
  &__preview {
    margin-bottom: 36px;
  }
  &__title {
    margin-bottom: 12px;
    @include m {
      font-size: 20px;
    }
  }
  &__text {
    max-width: 380px;
    margin: 0 auto;
    color: $gray;
  }
  &__btn {
    min-width: 178px;
    margin-top: 24px;
  }

  &_sm {
    min-height: 0;
  }
  &_sm &__preview {
    max-width: 246px;
    margin: 0 auto 36px;
  }
}
