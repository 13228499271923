.panel {
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin-top: 50px;
  background: white;
  border-radius: 12px;
  //box-shadow:  0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
  box-shadow: 0 6px 12px rgb(140 152 164 / 8%);
  transition: 0.3s;
  border: 0.0625rem solid rgba(231, 234, 243, 0.7);
  border-radius: 0.75rem;

  &:hover {
    box-shadow: 0 6px 12px rgba(140, 152, 164, 0.25) !important;
  }

  &_fluid {
    padding: 0 4px;
    border: none;
    box-shadow: none;

    &:hover {
      box-shadow: none !important;
    }
  }

  @include m {
    padding: 0;
    background: none;
    margin-top: 0px;
  }
  &__head {
    display: flex;
    margin-bottom: 24px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @include m {
      display: block;
      margin-bottom: 18px;
    }
    &_line {
      @include m {
        display: flex;
      }
    }
  }
  &__head &__title {
    padding: 5px 0 4px;

    .la {
      color: $gray;
      font-size: 1.5rem;
      padding-right: 10px;
      position: relative;
      top: 2px;
    }

    @include m {
      margin-bottom: 18px;
      padding: 0;
    }
  }
  &__head_line &__title {
    @include m {
      margin: 0;
    }
  }
  &__group {
    @include m {
      justify-content: space-between;
    }
  }
  &__text {
    color: $gray;
  }
  &__body {
    &_bg {
      @include m {
        padding: 24px;
        background: white;
        border-radius: 16px;
      }
    }
  }
  &__info &__title {
    .la {
      margin-left: 4px;
      font-size: 22px;
    }
  }
  &__chart {
    height: 280px;
    &_md {
      height: 220px;
    }
    &_sm {
      height: 180px;
    }
    &_pie {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__series {
    margin-top: 20px;
  }
  &__stats {
    display: flex;
    margin: 24px -24px -24px;
    border-top: 1px solid $border;
    text-align: center;
  }
  &__stat {
    padding: 24px 12px;
    flex: 1;
    &:not(:last-child) {
      border-right: 1px solid $border;
    }
  }
  &__map {
    margin: 0 -24px;
  }
  &__foot {
    display: flex;
    padding-top: 24px;
    margin-top: auto;
    align-items: center;
    @include m {
      margin-top: 18px;
    }
  }
  &__foot &__text {
    margin-left: 18px;
  }

  &-flat {
    box-shadow: none;
    &:hover {
      box-shadow: none !important;
    }
  }

  &-border {
    padding: 0;
    .panel__head {
      border-bottom: 1px solid rgba(231, 234, 243, 0.7);
      padding: 24px;
      margin-bottom: 0px;
    }

    .panel__body {
      padding: 24px;
    }
  }

  &-summary {
    background: #fbfbfd;
    padding: 0;
    border: none;
    box-shadow: none;
    //box-shadow: 0 6px 12px rgb(140 152 164 / 8%);
    //box-shadow: 0 0 0 1px rgb(140 152 164 / 5%), 0 1px 3px 0 rgb(140 152 164 / 15%);

    &:hover {
      box-shadow: none !important;
    }
    .panel__head {
      border-bottom: 1px solid rgba(231, 234, 243, 0.6);
      padding: 24px;
      margin-bottom: 12px;
    }

    .panel__body {
      padding: 0 24px 0px 24px;
    }
  }

  .divider {
    height: 1px;
  }

  etrk-select,
  etrk-input,
  etrk-datepicker,
  .colpush {
    flex-grow: 1;
  }

  .panel_body_row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: flex-start;

    .colspace:not(:last-child) {
      margin-right: 24px;
    }

    .inline-action {
      margin-left: 20px;
    }
  }

  .panel_info {
    display: flex;
    border-radius: 4px;
    background: #eff2fe;
    padding: 15px;
    color: #5e81f4;
  }

  dl {
    flex-grow: 1;

    dt {
      clear: left;
      float: left;
      line-height: 26px;
      width: 170px;
      font-weight: 400;
    }

    dd {
      clear: right;
      line-height: 18px;
      padding: 4px 0 4px 40px;
      font-weight: 300;
      float: left;
    }

    &.fluid dt {
      width: auto;
    }

    &.fluid dd {
      padding-left: 12px;
    }
  }
}

.panel__icon-preview {
  width: 75px;
  height: 75px;
}
