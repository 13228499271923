.menu {
  height: 100%;
  &__head {
    display: none;
    margin: -16px -16px 16px;
    padding: 22px 16px;
    background: white;
    align-items: center;
    @include m {
      display: flex;
    }
  }
  &__head &__item {
    padding: 0;
    flex: 1 1 auto;
    &:not(:last-child) {
      border: none;
    }
  }
  &__head &__icon {
    margin-right: 12px;
    border: 1px solid $border;
    .la {
      font-size: 18px;
      color: $gray;
    }
  }
  &__head &__action {
    margin-left: 16px;
  }
  &__container {
    display: flex;
    height: 100%;
    flex-direction: column;
    @include m {
      display: none;
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      height: auto;
      max-height: calc(100% - 68px);
      background: white;
      border-radius: 6px 6px 0 0;
      overflow: auto;
      z-index: 99;
      &.visible {
        display: block;
      }
    }
  }
  &__section {
    border: 1px solid $border;
    border-radius: 12px;

    &--danger {
      border-color: #ff808b;
      .menu__icon,
      .menu__title {
        color: #ff808b;
      }
    }

    @include m {
      border: none;
      border-radius: 0;
    }
    &:first-child {
      margin-bottom: auto;
    }
    &:last-child:not(:first-child) {
      margin-top: 40px;
      @include m {
        margin: 0;
        border-top: 1px solid $border;
      }
    }
  }
  &__section &__item {
    @include m {
      padding: 18px 24px;
    }
    &:hover {
      color: $gray;
    }
    &.active {
      color: $blue;
    }
  }
  &__section &__icon {
    @include m {
      margin-right: 16px;
      background: rgba($blue, 0.1);
      .la {
        color: $blue;
      }
    }
  }
  &__section &__text {
    @include m {
      display: none;
    }
  }
  &__item {
    display: flex;
    padding: 24px 32px;
    color: $cl;
    align-items: center;
    transition: color 0.2s;
    &:not(:last-child) {
      border-bottom: 1px solid $border;
    }
  }
  &__icon {
    margin-right: 22px;
    @include m {
      display: flex;
      height: 40px;
      border-radius: 8px;
      align-items: center;
      justify-content: center;
      flex: 0 0 40px;
    }
    .la {
      font-size: 22px;
    }
  }
  &__title {
    font-weight: 700;
  }
  &__text {
    font-size: 12px;
    color: $gray;
  }
  &__badge {
    margin-left: auto;
  }

  &_sidebar {
    min-height: calc(100vh - 144px);
    @include m {
      min-height: 0;
    }
  }
  &_sidebar &__head {
    @include m {
      margin: -16px;
    }
  }
  &_sidebar &__section {
    &:last-child:not(:first-child) {
      margin-top: 28px;
      @include m {
        margin: 0;
      }
    }
  }
}
