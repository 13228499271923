.pager {
  width: 100%;
  justify-content: space-between;
  &,
  &__list {
    display: flex;
  }
  &__arrow {
    font-size: 12px;
    text-transform: uppercase;
    &.disabled {
      opacity: 0.5;
    }
    @include m {
      padding: 0 9px;
      font-size: 0;
      .la {
        margin: 0;
      }
    }
  }
  &__link {
    position: relative;
    margin: 0 4px;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      bottom: -24px;
      height: 2px;
      background: $blue;
      border-radius: 2px;
      opacity: 0;
      transition: opacity 0.2s;
      pointer-events: none;
    }
    &.active {
      background: $blue-light;
      color: $blue;
      &:after {
        opacity: 1;
      }
    }
  }
}
