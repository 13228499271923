body {
  @include lato;
  font-size: 15px;
  color: $cl;
  line-height: 1.5;
  &.no-scroll {
    overflow: hidden;
  }
}

button,
input,
textarea,
select {
  @include lato;
  font-size: 15px;
  color: $cl;
  font-weight: 500;
}
button {
  background: transparent;
  line-height: 1;
}
input,
textarea {
  &::placeholder {
    color: rgba($gray, 0.85);
  }
}
textarea {
  display: block;
  width: 100%;
  height: 72px;
  background: none;
  border: none;
  resize: none;
}
a {
  color: $blue;
  text-decoration: none;
  &:active {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  }
}
img {
  display: block;
  max-width: 100%;
}

router-outlet + * {
  display: block;
  width: 100%;
}

// +scrollbar
//   width: 4px
//   height: 4px
// +scrollbar-track
//   background: $bg
// +scrollbar-thumb
//   background: rgba($blue,.5)

.pac-container {
  z-index: 999999 !important;
  //margin-top: 2px;
  border-top: none;
  box-shadow: 0 10px 40px 10px rgb(140 152 164 / 18%);
  border-radius: 0;
  border-bottom-right-radius: 0.75em;
  border-bottom-left-radius: 0.75em;
}

.pac-item {
  font-size: 14px;
  color: $gray;
  font-family: 'system-ui', sans-serif;
  padding: 0 9px;

  &:not(:last-child) {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  &:hover {
    background-color: rgba($gray, 0.1);
  }

  border-top: 1px solid rgba($gray, 0.1);
}

.hdpi.pac-logo::after {
  background: none !important;
}
