.notify {
  display: flex;
  @include t {
    flex-direction: column-reverse;
  }
  &__list {
    flex: 1 1 auto;
  }
  &__list_cols {
    column-count: 2;
    column-gap: 24px;
    @include t {
      column-count: 1;
    }
  }
  &__list_cols &__item {
    display: inline-block;
    width: 100%;
    vertical-align: top;
  }
  &__list_cols &__photo {
    flex: 0 0 calc(50% - 12px);
  }
  &__item {
    padding: 24px 28px 28px;
    border: 1px solid $border;
    border-radius: 12px;
    @include m {
      padding: 20px 14px 14px;
      background: white;
      border: none;
    }
    &:not(:last-child) {
      margin-bottom: 24px;
      @include m {
        margin-bottom: 8px;
      }
    }
  }
  &__head {
    display: flex;
    margin-bottom: 24px;
    align-items: center;
    justify-content: space-between;
  }
  &__action {
    margin-left: 20px;
  }
  &__body {
    margin-bottom: 24px;
    padding-bottom: 28px;
    border-bottom: 1px solid $border;
  }
  &__gallery {
    display: flex;
    margin: 0 -6px 4px;
  }
  &__photo {
    margin: 0 6px 12px;
    flex: 0 0 calc(33.33% - 12px);
  }
  &__pic {
    border-radius: 4px;
  }
  &__sidebar {
    margin-left: 46px;
    padding-top: 20px;
    flex: 0 0 300px;
    @include t {
      margin: 0 0 18px;
      padding: 0;
      flex: 0;
    }
  }
  &__title {
    margin-bottom: 30px;
  }
}
