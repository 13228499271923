.sort {
  position: relative;
  @include m {
    margin-left: 6px;
  }
  &__head {
    display: flex;
    font-size: 12px;
    color: $gray;
    font-weight: 700;
    text-transform: uppercase;
    align-items: center;
    cursor: pointer;
    @include m {
      border-radius: 6px;
    }
  }
  &__icon {
    display: flex;
    height: 36px;
    margin-right: 5px;
    border-radius: 6px;
    align-items: center;
    @include m {
      width: 36px;
      margin: 0;
      background: white;
      justify-content: center;
    }
    .la {
      display: block;
      font-size: 18px;
    }
  }
  &__label,
  &__selected,
  &__arrow {
    @include m {
      display: none;
    }
  }
  &__label {
    margin-right: 5px;
  }
  &__selected {
    color: $cl;
  }
  &__arrow {
    margin-left: 8px;
    .la {
      display: block;
      position: relative;
      top: -2px;
    }
  }
  &__dropdown {
    display: none;
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    min-width: 280px;
    padding: 24px;
    background: white;
    border-radius: 6px;
    z-index: 99;
    @include m {
      position: fixed;
      top: auto;
      left: 0;
      bottom: 0;
      width: 100%;
      max-height: calc(100% - 68px);
      border-radius: 16px 16px 0 0;
      overflow: auto;
    }
  }
  &__title {
    color: $gray;
  }
  &__items {
    margin: 0 -24px;
  }
  &__item {
    padding: 24px;
    &:not(:last-child) {
      border-bottom: 1px solid $border;
    }
  }
  &__foot {
    margin-top: 6px;
  }
  &__btn {
    width: 100%;
  }

  &.open &__dropdown,
  &.open &__backdrop {
    display: block;
  }
}
