.upload {
  display: flex;
  position: relative;
  height: 206px;
  border: 1px dashed $border;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  @include m {
    height: 100px;
  }
  &__input {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 200px;
    opacity: 0;
    cursor: pointer;
  }
  &__inner {
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
  }
  &__preview {
    margin-bottom: 14px;
  }
  &__title {
    font-size: 16px;
    font-weight: 700;
  }
  &_md {
    height: 240px;
    @include m {
      height: 340px;
    }
  }

  &_lg {
    height: 340px;
    @include m {
      height: 340px;
    }
  }
}
