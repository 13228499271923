.accordion {
  max-width: 882px;
  margin: 0 auto;
  &__item {
    border-bottom: 1px solid $border;
  }
  &__head {
    display: flex;
    padding: 24px 0;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  &__head.active &__title {
    color: $blue;
  }
  &__head.active &__arrow {
    background: rgba($blue, 0.1);
    border-color: transparent;
    color: $blue;
  }
  &__title {
    transition: color 0.2s;
  }
  &__arrow {
    display: flex;
    width: 32px;
    height: 32px;
    border: 1px solid $border;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
  }
  &__body {
    display: none;
    padding-bottom: 24px;
  }
}
