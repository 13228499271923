// colors
$blue: #5e81f4; //#f39c12 5e81f4
$blue-light: #eff2fe;
$blue-dark: #121f40;
$gray: #8181a5;
$gray-light: rgba($gray, 0.04);
$yellow: #ec9a3c;
$green: #008488;
$red: #ed4c78;
$orange: #fd6f44;
$purple: #9698d6;
$turquoise: #2ce5f6;

$cl: #1c1d21;
$border: #f0f0f3;
$btn-hover: #475eaa;
$btn-outline: #ececf2;
$bg: #f6f6f6;
$bg-light: #fff; //f5f5fa
$bg-lighter: #fbfbfd;

:root {
  --blue: #5e81f4; //#f39c12 5e81f4
  --blue-light: #eff2fe;
  --gray: #8181a5;
  --gray-light: rgba($gray, 0.04);
  --yellow: #ec9a3c;
  --orange: #fd6f44;
  --green: #008488;
  --red: #ed4c78;
  --purple: #9698d6;
  --turquoise: #2ce5f6;

  --cl: #1c1d21;
  --border: #f0f0f3;
  --btn-hover: #475eaa;
  --btn-outline: #ececf2;
  --bg: #f6f6f6;
  --bg-light: #fff; //f5f5fa
  --bg-lighter: #fbfbfd;
}
