.history {
  &__container {
    background: $bg-lighter;
    border-radius: 16px;
  }
  &__container &__head {
    padding: 20px;
  }
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__text {
    color: $gray;
  }
  &__link {
    display: flex;
    height: 42px;
    margin-left: 16px;
    background: $bg-light;
    border-radius: 8px;
    color: $gray;
    align-items: center;
    justify-content: center;
    transition: color 0.2s;
    flex: 0 0 42px;
    &:hover {
      color: $cl;
    }
  }
  &__chart {
    svg {
      border-radius: 0 0 16px 16px;
    }
  }
}
