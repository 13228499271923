.user {
  display: flex;
  align-items: center;
  &__preview {
    display: flex;
    height: 36px;
    box-shadow: 0 0 0 1px $border inset;
    align-items: center;
    justify-content: center;
    flex: 0 0 36px;
  }
  &__pic {
    display: block;
    width: 100%;
    border-radius: 6px;
  }
  &__details {
    margin-left: 14px;
  }
  &__name {
    font-weight: 700;
  }
  &__text {
    color: $gray;
  }
}

.user-group {
  .user {
    &:not(:last-child) {
      margin-bottom: 18px;
    }
    &__preview {
      height: 52px;
      border-radius: 8px;
      flex: 0 0 52px;
    }
    &__pic {
      border-radius: 8px;
    }
    &__details {
      margin-left: 22px;
    }
  }

  &_members {
    @include t {
      display: flex;
      overflow: auto;
      &:after {
        content: '';
        flex: 0 0 8px;
      }
    }
    @include m {
      margin: -16px -16px 0;
      padding: 18px 0 18px 20px;
      background: white;
      &:after {
        flex: 0 0 20px;
      }
    }
    .user {
      @include t {
        flex: 0 0 52px;
        &:not(:last-child) {
          margin: 0 8px 0 0;
        }
      }
      &__details {
        @include t {
          display: none;
        }
      }
    }
  }
}
