.switch {
  display: block;
  position: relative;
  font-size: 14px;
  font-weight: 700;
  color: $gray;
  line-height: 1.46;
  user-select: none;
  cursor: pointer;
  &__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
  &__input[disabled] + &__content {
    opacity: 0.7;
    cursor: not-allowed;
  }
  &__input:checked + &__content {
    font-weight: 700;
    color: $cl;
    &:before {
      background: $blue;
    }
    &:after {
      opacity: 1;
    }
  }
  &__input[type='checkbox'] + &__content {
    &:after {
      top: 6px;
      left: 5px;
      width: 11px;
      height: 8px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='8' fill='none'%3E%3Cpath d='M3.64 7.84l-3.5-3.5c-.2-.2-.2-.55 0-.76l.76-.76c.2-.2.55-.2.76 0L4.02 5.2 9.05.16c.2-.2.55-.2.76 0l.76.76c.2.2.2.55 0 .76L4.4 7.84c-.2.2-.55.2-.76 0z' fill='%23fff'/%3E%3C/svg%3E");
    }
  }
  &__input[type='checkbox'].error + &__content {
    &:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='8' fill='none'%3E%3Cpath d='M3.64 7.84l-3.5-3.5c-.2-.2-.2-.55 0-.76l.76-.76c.2-.2.55-.2.76 0L4.02 5.2 9.05.16c.2-.2.55-.2.76 0l.76.76c.2.2.2.55 0 .76L4.4 7.84c-.2.2-.55.2-.76 0z' fill='%23FF808B'/%3E%3C/svg%3E");
    }
  }
  &__input[type='checkbox'].success + &__content {
    &:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='8' fill='none'%3E%3Cpath d='M3.64 7.84l-3.5-3.5c-.2-.2-.2-.55 0-.76l.76-.76c.2-.2.55-.2.76 0L4.02 5.2 9.05.16c.2-.2.55-.2.76 0l.76.76c.2.2.2.55 0 .76L4.4 7.84c-.2.2-.55.2-.76 0z' fill='%237CE7AC'/%3E%3C/svg%3E");
    }
  }
  &__input[type='radio'] + &__content {
    &:before {
      border-radius: 50%;
    }
    &:after {
      top: 6px;
      left: 6px;
      width: 8px;
      height: 8px;
      background: white;
      border-radius: 50%;
    }
  }
  &__input[type='radio'].error + &__content {
    &:after {
      background: $red;
    }
  }
  &__input[type='radio'].success + &__content {
    &:after {
      background: $green;
    }
  }
  &__input.error + &__content {
    color: $red;
    &:before {
      background: rgba($red, 0.15);
    }
  }
  &__input.success + &__content {
    color: $green;
    &:before {
      background: rgba($green, 0.15);
    }
  }
  &__content {
    display: block;
    min-height: 20px;
    padding-left: 32px;
    transition: opacity 0.2s, color 0.2s;
    &:before,
    &:after {
      content: '';
      position: absolute;
    }
    &:before {
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      background: $border;
      border-radius: 4px;
      transition: background 0.2s;
    }
    &:after {
      opacity: 0;
      transition: all 0.2s;
    }
    &:empty {
      padding-left: 20px;
    }
  }
  &__typing {
    width: 100%;
    height: 20px;
  }

  &_toggle &__input[type='checkbox'] + &__content {
    min-height: 22px;
    padding-left: 58px;
    &:before {
      top: -1px;
      width: 40px;
      height: 22px;
      border-radius: 11px;
    }
    &:after {
      top: 2px;
      left: 3px;
      width: 16px;
      height: 16px;
      background: #98a9bc;
      border-radius: 50%;
      opacity: 1;
    }
    &:empty {
      padding-left: 40px;
    }
  }
  &_toggle &__input[type='checkbox']:checked + &__content {
    &:after {
      background: white;
      transform: translateX(18px);
    }
  }
  &_toggle &__input.error + &__content {
    &:before {
      background: $red;
    }
    &:after {
      background: white;
    }
  }
  &_toggle &__input.success + &__content {
    &:before {
      background: $green;
    }
    &:after {
      background: white;
    }
  }

  &__group {
    display: flex;

    .switch:not(:last-child) {
      margin-right: 15px;
    }
  }
}
