.details {
  margin-bottom: 22px;
  border: 1px solid $border;
  border-radius: 12px;
  @include m {
    margin: 0 -16px 22px;
    border-width: 1px 0;
    border-radius: 0;
  }
  &__row {
    display: flex;
    padding: 14px;
    @include t {
      flex-wrap: wrap;
    }
    @include m {
      padding: 10px;
    }
    &:not(:last-child) {
      border-bottom: 1px solid $border;
    }
  }
  &__cell {
    padding: 10px;
    @include m {
      padding: 6px;
    }
    &_lg {
      flex: 1 1 auto;
    }
  }
  &__link {
    font-weight: 700;
  }
}
