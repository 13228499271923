.activity {
  &__section {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  &__head {
    margin-bottom: 20px;
    @include m {
      margin-bottom: 12px;
    }
  }
  &__item {
    position: relative;
    padding: 16px 20px 20px;
    background: white;
    border: 1px solid $border;
    border-radius: 12px;
    &:not(:last-child) {
      margin-bottom: 14px;
      @include m {
        margin-bottom: 4px;
      }
    }
  }
  &__action {
    position: absolute;
    top: 12px;
    right: 12px;
  }
  &__title {
    margin-bottom: 6px;
    padding-right: 80px;
  }
  &__text {
    margin-bottom: 14px;
    padding-right: 80px;
    @include m {
      padding-right: 40px;
    }
    color: $gray;
  }
  &__gallery {
    display: flex;
    margin: 0 -6px 2px;
    flex-wrap: wrap;
    @include m {
      margin: 0 -3px 8px;
    }
  }
  &__preview {
    max-width: 174px;
    margin: 0 6px 12px;
    flex: 0 0 calc(33.33% - 12px);
    @include m {
      margin: 0 3px 6px;
    }
  }
  &__pic {
    display: block;
    width: 100%;
    border-radius: 12px;
  }
}
