.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  height: 30px;
  line-height: 30px;
  background-color: #5e81f4;
  color: #eff2fe;
  text-align: center;
  padding: 0 10px;
  border-radius: 6px;
  position: absolute;
  font-size: 0.8em;
  z-index: 1;
  top: 50%;
  left: 100%;
  margin-top: -15px;
  opacity: 0;
  transition: opacity 0.3s;
}

// .tooltip .tooltiptext::after {
//   content: '';
//   position: absolute;
//   top: 100%;
//   left: 50%;
//   margin-left: -5px;
//   border-width: 5px;
//   border-style: solid;
//   border-color: #555 transparent transparent transparent;
// }

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
