.messages {
  &__layout {
    display: flex;
    position: relative;
    @include t {
      display: block;
    }
  }
  &__sidebar {
    display: flex;
    width: 354px;
    margin-right: 28px;
    flex-direction: column;
    @include t {
      width: auto;
      height: auto;
      margin: 0 0 32px;
    }
  }
  &__head {
    display: flex;
    min-height: 40px;
    margin-bottom: 24px;
    align-items: center;
    justify-content: space-between;
    @include m {
      display: block;
      min-height: 0;
    }
  }
  &__head &__btn {
    height: 40px;
    padding: 0 16px;
  }
  &__group {
    margin-left: 32px;
    justify-content: flex-end;
    @include m {
      margin: 24px 0 0;
      justify-content: flex-start;
    }
  }
  &__body {
    max-height: calc(100vh - 224px);
    flex: 1 1 auto;
    overflow: auto;
    @include t {
      max-height: unset;
    }
  }
  &__container {
    flex: 0 0 calc(100% - 382px);
  }
  &__chat {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - 382px);
    height: 100%;
  }
}
