.form {
  &__section {
    margin-bottom: 8px;
  }
  &__line {
    display: flex;
    margin-bottom: 20px;
    padding: 16px;
    background: $bg-lighter;
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    margin-bottom: 20px;
    font-weight: 700;
  }
  &__subtitle {
    margin-right: 40px;
    color: $gray;
    font-weight: 700;
  }
  &__preview {
    display: flex;
    position: relative;
    width: 108px;
    height: 108px;
    margin: 0 auto 32px;
    background: rgba($blue, 0.1);
    border-radius: 8px;
    color: $blue;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .la {
      font-size: 32px;
    }
  }
  &__file {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    font-size: 40px;
    opacity: 0;
    cursor: pointer;
  }
  &__pic {
    border-radius: 8px;
  }
  &__field,
  &__details {
    margin-bottom: 22px;
  }
  &__row {
    display: flex;
    margin: 0 -16px;
    @include m {
      display: block;
    }
  }
  &__row:not(:first-child) {
    margin-top: 20px;
  }
  &__col {
    display: flex;
    align-items: center;
    padding: 0 16px;
    flex: 0 0 50%;

    & > * {
      width: 100%;
    }

    &.col--1x {
      flex: 0 0 100%;
    }

    &.col--3x {
      flex: 0 0 33.3333333%;
    }

    &.col--4x {
      flex: 0 0 25%;
    }
  }
  &__foot {
    display: flex;
    margin-top: 40px;
    justify-content: space-between;
  }
  &__btn {
    min-width: 136px;
  }

  &_settings &__row {
    @include t {
      display: block;
    }
    @include m {
      margin-bottom: 40px;
    }
  }

  &_create {
    @include m {
      margin: 16px;
      padding: 16px;
      background: white;
      border-radius: 6px;
    }
  }
}
