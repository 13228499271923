.tasks {
  &__item {
    display: flex;
    min-height: 62px;
    padding: 14px 12px;
    background: white;
    border-radius: 10px;
    border: 1px solid $border;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    &_add {
      border-style: dashed;
    }
  }
  &__cell {
    padding: 0 8px;
    &_lg {
      flex: 1 1 auto;
    }
  }
  &__field {
    margin-left: -4px;
  }
  &__input {
    width: 100%;
    height: 36px;
  }
  &__preview {
    width: 36px;
  }
  &__pic {
    display: block;
    width: 100%;
    border-radius: 6px;
  }
  &__foot {
    margin-top: 16px;
    text-align: center;
  }
}
