.gallery {
  display: flex;
  margin-bottom: 34px;
  padding: 16px 0 16px 16px;
  border: 1px dashed $border;
  border-radius: 12px;
  overflow: auto;
  &:after {
    content: '';
    flex: 0 0 8px;
  }
  &__item {
    position: relative;
    margin-right: 8px;
    flex: 0 0 174px;
  }
  &__pic {
    border-radius: 8px;
  }
  &__action {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}
