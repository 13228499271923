.green {
  background: rgba($green, 0.1) !important;
  color: $green !important;
}
.red {
  background: rgba($red, 0.1) !important;
  color: $red !important;
}
.blue {
  background: rgba($blue, 0.1) !important;
  color: $blue !important;
}
.yellow {
  background: rgba($yellow, 0.1) !important;
  color: $yellow !important;
}
.orange {
  background: rgba($orange, 0.1) !important;
  color: $orange !important;
}
.purple {
  background: rgba($purple, 0.1) !important;
  color: $purple !important;
}
.gray {
  background: rgba($gray, 0.1) !important;
  color: $gray !important;
}
.gray-light {
  background: rgba($gray, 0.07) !important;
  color: $gray !important;
}
.turquoise {
  background: rgba($turquoise, 0.1) !important;
  color: $gray !important;
}

.color {
  &-black {
    color: $cl !important;
  }
  &-green {
    color: $green !important;
  }
  &-red {
    color: $red !important;
  }
  &-blue {
    color: $blue !important;
  }
  &-blue-dark {
    color: $blue-dark !important;
  }
  &-orange {
    color: $orange !important;
  }
  &-yellow {
    color: $yellow !important;
  }
  &-purple {
    color: $purple !important;
  }
  &-gray {
    color: $gray !important;
  }
  &-turquoise {
    color: $turquoise !important;
  }
}

.bg {
  &-green {
    background: rgba($green, 0.1) !important;
    border-color: rgba($green, 0.1) !important;
    &-raw {
      background: $green !important;
      border-color: $green !important;
    }
  }
  &-red {
    background: rgba($red, 0.1) !important;
    border-color: rgba($red, 0.1) !important;
    &-raw {
      background: $red !important;
      border-color: $red !important;
    }
  }
  &-blue {
    background: rgba($blue, 0.1) !important;
    border-color: rgba($blue, 0.1) !important;
    &-raw {
      background: $blue !important;
      border-color: $blue !important;
    }
  }
  &-yellow {
    background: rgba($yellow, 0.1) !important;
    border-color: rgba($yellow, 0.1) !important;
    &-raw {
      background: $yellow !important;
      border-color: $yellow !important;
    }
  }
  &-purple {
    background: rgba($purple, 0.1) !important;
    border-color: rgba($purple, 0.1) !important;
    &-raw {
      background: $purple !important;
      border-color: $purple !important;
    }
  }
  &-gray {
    background: rgba($gray, 0.1) !important;
    border-color: rgba($gray, 0.1) !important;
    &-raw {
      background: $gray !important;
      border-color: $gray !important;
    }
  }
  &-gray-light {
    background: rgba($gray, 0.04) !important;
    border-color: rgba($gray, 0.04) !important;
  }
  &-turquoise {
    background: rgba($turquoise, 0.1) !important;
    border-color: rgba($turquoise, 0.1) !important;
    &-raw {
      background: $turquoise !important;
      border-color: $turquoise !important;
    }
  }
}

.text {
  &-right {
    text-align: right !important;
  }
  &-center {
    text-align: center !important;
  }
  &-uppercase {
    text-transform: uppercase !important;
  }
  &-lowercase {
    text-transform: lowercase !important;
  }
  &-capitalize {
    text-transform: capitalize !important;
  }
  &-bold {
    font-weight: 500 !important;
  }
  &-light {
    font-weight: 300 !important;
  }
  &-hide {
    text-indent: -99999px;
    opacity: 0;
  }
}

.flex {
  &-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.m-auto {
  margin: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}

.mt {
  &_6 {
    margin-top: 5px !important;
  }

  &_10 {
    margin-top: 10px !important;
  }

  &_15 {
    margin-top: 15px !important;
  }

  &_20 {
    margin-top: 20px !important;
  }

  &_25 {
    margin-top: 25px !important;
  }

  &_30 {
    margin-top: 30px !important;
  }
}

.ml {
  &_6 {
    margin-left: 5px !important;
  }

  &_10 {
    margin-left: 10px !important;
  }

  &_15 {
    margin-left: 15px !important;
  }

  &_20 {
    margin-left: 20px !important;
  }

  &_25 {
    margin-left: 25px !important;
  }

  &_30 {
    margin-left: 30px !important;
  }
}

.mr {
  &_6 {
    margin-right: 5px !important;
  }

  &_10 {
    margin-right: 10px !important;
  }

  &_15 {
    margin-right: 15px !important;
  }

  &_20 {
    margin-right: 20px !important;
  }

  &_25 {
    margin-right: 25px !important;
  }

  &_30 {
    margin-right: 30px !important;
  }
}

.mb {
  &_6 {
    margin-bottom: 5px !important;
  }

  &_10 {
    margin-bottom: 10px !important;
  }

  &_15 {
    margin-bottom: 15px !important;
  }

  &_20 {
    margin-bottom: 20px !important;
  }

  &_25 {
    margin-bottom: 25px !important;
  }

  &_30 {
    margin-bottom: 30px !important;
  }
}

.p {
  &_6 {
    padding: 5px !important;
  }

  &_10 {
    padding: 10px !important;
  }

  &_15 {
    padding: 15px !important;
  }

  &_20 {
    padding: 20px !important;
  }

  &_25 {
    padding: 25px !important;
  }

  &_30 {
    padding: 30px !important;
  }
}

.pt {
  &_6 {
    padding-top: 5px !important;
  }

  &_10 {
    padding-top: 10px !important;
  }

  &_15 {
    padding-top: 15px !important;
  }

  &_20 {
    padding-top: 20px !important;
  }

  &_25 {
    padding-top: 25px !important;
  }

  &_30 {
    padding-top: 30px !important;
  }
}

.pl {
  &_6 {
    padding-left: 5px !important;
  }

  &_10 {
    padding-left: 10px !important;
  }

  &_15 {
    padding-left: 15px !important;
  }

  &_20 {
    padding-left: 20px !important;
  }

  &_25 {
    padding-left: 25px !important;
  }

  &_30 {
    padding-left: 30px !important;
  }
}

.pr {
  &_6 {
    padding-right: 5px !important;
  }

  &_10 {
    padding-right: 10px !important;
  }

  &_15 {
    padding-right: 15px !important;
  }

  &_20 {
    padding-right: 20px !important;
  }

  &_25 {
    padding-right: 25px !important;
  }

  &_30 {
    padding-right: 30px !important;
  }
}

.pb {
  &_6 {
    padding-bottom: 5px !important;
  }

  &_10 {
    padding-bottom: 10px !important;
  }

  &_15 {
    padding-bottom: 15px !important;
  }

  &_20 {
    padding-bottom: 20px !important;
  }

  &_25 {
    padding-bottom: 25px !important;
  }

  &_30 {
    padding-bottom: 30px !important;
  }
}

.align {
  &-baseline {
    align-items: baseline !important;
  }
}

.desktop {
  &-hide {
    @include d {
      display: none !important;
    }
  }
  &-show {
    display: none !important;
    @include d {
      display: block !important;
    }
  }
  &-text-right {
    @include d {
      text-align: right !important;
    }
  }
}

.tablet {
  &-hide {
    @include t {
      display: none !important;
    }
  }
  &-show {
    display: none !important;
    @include t {
      display: block !important;
    }
  }
  &-text-right {
    @include t {
      text-align: right !important;
    }
  }
}

.mobile {
  &-hide {
    @include m {
      display: none !important;
    }
  }
  &-show {
    display: none !important;
    @include m {
      display: block !important;
    }
  }
  &-text-right {
    @include m {
      text-align: right !important;
    }
  }
}

.fs {
  &_normal {
    font-size: 15px;
  }
}

.box {
  &_rounded {
    border-radius: 50%;
  }
}

.flex {
  &_center {
    align-items: center;
    justify-content: center;
  }
}
