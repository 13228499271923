@import 'helpers/all';
// @import generated/sprite-svg

@import 'variables';
@import 'typography';
@import 'lib/line-awesome';
@import 'lib/icofont';
@import 'lib/select2.min';
@import 'lib/nouislider.min';
@import 'lib/datepicker/datepicker';
@import 'lib/datepicker/cell';
@import 'lib/datepicker/navigation';
@import 'lib/datepicker/timepicker';
@import 'common';

// blocks
@import 'blocks/tooltip';
@import 'blocks/apexcharts';
@import 'blocks/page';
@import 'blocks/backdrop';
@import 'blocks/btn';
@import 'blocks/action';
@import 'blocks/title';
@import 'blocks/datepicker';
@import 'blocks/accordion';
@import 'blocks/switch';
@import 'blocks/field';
@import 'blocks/select2';
@import 'blocks/upload';
@import 'blocks/slider';
@import 'blocks/control';
@import 'blocks/table';
@import 'blocks/meta';
@import 'blocks/badge';
@import 'blocks/caption';
@import 'blocks/progress';
@import 'blocks/enter';
@import 'blocks/auth';
@import 'blocks/online';
@import 'blocks/nav';
@import 'blocks/back';
@import 'blocks/stroke';
@import 'blocks/sidebar';
@import 'blocks/items';
@import 'blocks/messages';
@import 'blocks/widget';
@import 'blocks/events';
@import 'blocks/updates';
@import 'blocks/history';
@import 'blocks/series';
@import 'blocks/container';
@import 'blocks/empty';
@import 'blocks/panel';
@import 'blocks/tag';
@import 'blocks/grid';
@import 'blocks/pager';
@import 'blocks/search';
@import 'blocks/new';
@import 'blocks/layout';
@import 'blocks/sort';
@import 'blocks/members';
@import 'blocks/info';
@import 'blocks/data';
@import 'blocks/tasks';
@import 'blocks/desk';
@import 'blocks/user';
@import 'blocks/activity';
@import 'blocks/form';
@import 'blocks/menu';
@import 'blocks/settings';
@import 'blocks/overview';
@import 'blocks/calendar';
@import 'blocks/contacts';
@import 'blocks/details';
@import 'blocks/subscriptions';
@import 'blocks/modal';
@import 'blocks/reply';
@import 'blocks/content';
@import 'blocks/chat';
@import 'blocks/media';
@import 'blocks/gallery';
@import 'blocks/invoice';
@import 'blocks/file';
@import 'blocks/notify';
@import 'blocks/notice';
@import 'blocks/help';
@import 'blocks/tickets';
@import 'blocks/toast';

// modificators
@import 'modificators';

// Libs
//@import 'lib/select2.min';
@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/themes/light.css';
@import '~tippy.js/animations/scale.css';

.delete-tippy-box {
  .tippy-box {
    border-radius: 8px !important;
  }

  footer {
    padding-bottom: 4px;
  }
}
