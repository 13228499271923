.notice {
  padding: 24px;
  background: $bg-lighter;
  border-radius: 12px;
  &__text {
    color: $gray;
  }
  &__btn {
    margin-top: 16px;
  }
}
