.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 40px;
  overflow: auto;
  z-index: 999999;
  pointer-events: none !important;

  &__backdrop {
    background: rgba($cl, 0.35);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999999;
    backdrop-filter: blur(3px);
  }

  @include m {
    padding: 0;
  }
  &__container {
    position: relative;
    pointer-events: all !important;
    width: 100%;
    max-width: 800px;
    margin: auto;
    padding: 28px;
    background: white;
    border-radius: 4px;
    @include m {
      max-width: 100%;
      min-height: 100%;
      padding: 0;
      border-radius: 0;
    }
  }
  &__head {
    display: flex;
    position: relative;
    margin-bottom: 28px;
    z-index: 10;
    @include m {
      margin: 0;
      padding: 16px;
      background: white;
      border-bottom: 1px solid $bg-light;
    }
  }
  &__head &__action {
    margin-left: 16px;
    @include m {
      background: $bg-light;
      border-color: transparent;
    }
    &:first-child {
      display: none;
      margin: 0 16px 0 0;
      @include m {
        display: block;
      }
    }
  }
  &__container {
    @include m {
      background: $bg-light;
    }
  }
  &__wrap {
    align-self: center;
    flex: 1 1 auto;
  }
  &__title {
    font-weight: 700;
    color: $gray;
    @include m {
      color: $cl;
      text-align: center;
    }
  }

  &_md &__container {
    max-width: 1080px;
  }

  &_upload {
    @include m {
      background: white;
    }
  }
  &_upload &__container {
    @include m {
      padding: 24px;
      background: white;
    }
  }

  &.visible {
    display: flex;
  }
}
