.grid {
  margin: 0 -24px -24px;
  overflow: auto;
  @include m {
    margin: 0;
  }
  &__table {
    display: table;
    width: 100%;
    @include m {
      min-width: 100% !important;
    }
  }
  &__head {
    display: table-header-group;
    background: $bg-lighter;
    font-size: 12px;
    font-weight: 700;
    color: $gray;
    @include m {
      display: none;
    }
  }
  &__head &__cell {
    padding: 16px 12px;
  }
  &__filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  &__body {
    display: table-row-group;
  }
  &__body &__row:first-child &__cell {
    padding-top: 24px;
    @include m {
      padding-top: 6px;
    }
  }
  &__body &__row:last-child &__cell {
    padding-bottom: 24px;
    @include m {
      padding-bottom: 6px;
    }
  }
  &__row {
    display: table-row;
    @include m {
      display: flex;
      margin-bottom: 6px;
      padding: 8px;
      background: white;
      border-radius: 10px;
    }
  }
  &__cell {
    display: table-cell;
    padding: 12px;
    vertical-align: middle;
    @include m {
      padding: 8px;
      flex: 1 1 auto;
    }
    &:first-child {
      padding-left: 24px;
      @include m {
        padding-left: 6px;
      }
    }
    &:last-child {
      padding-right: 24px;
      @include m {
        padding-right: 6px;
      }
    }
  }
  &__flex {
    display: flex;
    align-items: center;
    @include m {
      align-items: flex-start;
    }
  }
  &__preview {
    display: flex;
    width: 52px;
    height: 52px;
    margin-right: 20px;
    background: $blue-light;
    border-radius: 8px;
    color: $blue;
    align-items: center;
    justify-content: center;
    flex: 0 0 52px;
  }
  &__pic {
    border-radius: 8px;
  }
  &__bold {
    font-weight: 700;
  }
}
