.media {
  overflow: hidden;
  &__list {
    display: flex;
    margin: 0 -8px -16px;
    flex-wrap: wrap;
  }
  &__item {
    display: flex;
    margin: 0 8px 16px;
    padding: 16px 16px 30px;
    border: 1px solid $border;
    border-radius: 12px;
    text-align: center;
    flex-direction: column;
    flex: 0 0 calc(33.33% - 16px);
    @include m {
      background: white;
      flex: 0 0 calc(50% - 16px);
    }
  }
  &__action {
    margin: 0 0 16px auto;
  }
  &__format {
    display: flex;
    width: 54px;
    height: 64px;
    margin: 0 auto 20px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
  }
  &__preview &__pic {
    border-radius: 8px;
  }
  &__name {
    font-weight: 700;
  }
  &__text {
    color: $gray;
  }
}
