.content {
  &__head {
    display: flex;
    margin-bottom: 34px;
    align-items: center;
    @include m {
      margin: 0;
      padding: 22px 16px;
      background: white;
    }
  }
  &__head &__title {
    margin: 0;
  }
  &__head &__wrap {
    flex: 1 1 auto;
  }
  &__container {
    @include m {
      padding: 16px;
    }
  }
  &__section {
    margin-bottom: 20px;
    padding-bottom: 28px;
    @include m {
      margin: 0;
      padding: 0;
    }
    &:not(:last-child) {
      border-bottom: 1px solid $border;
      @include m {
        border: none;
      }
    }
  }
  &__box {
    @include m {
      margin-bottom: 4px;
      padding: 12px 16px 18px;
      background: white;
      border-radius: 6px;
    }
  }
  &__box &__title {
    @include m {
      margin-bottom: 12px;
      font-size: 12px;
      color: #8181a5;
    }
  }
  &__title {
    margin-bottom: 12px;
  }
  &__flex {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  &__stats {
    font-size: 18px;
  }
  &__preview {
    display: flex;
    position: relative;
    height: 48px;
    margin-right: 14px;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px $border;
    align-items: center;
    justify-content: center;
    flex: 0 0 48px;
    overflow: hidden;
  }
  &__file {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  &__pic {
    border-radius: 8px;
  }
  &__letters {
    font-size: 30px;
    font-weight: 700;
  }
  &__text {
    color: $gray;
  }
  &__row {
    display: flex;
    margin: 0 -16px;
    @include m {
      display: block;
    }
  }
  &__col {
    padding: 0 16px;
    flex: 0 0 50%;
  }
  &__label {
    margin-bottom: 12px;
    font-size: 12px;
    font-weight: 700;
    color: $gray;
  }
  &__progress {
    margin-bottom: 24px;
  }
  &__switch {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
  &__actions {
    display: flex;
    margin: 0 -28px -28px;
    border-top: 1px solid $border;
  }
  &__caption {
    height: 58px;
    flex: 1;
    &:not(:last-child) {
      border-right: 1px solid $border;
    }
  }
  &__person {
    margin-bottom: 20px;
    padding-bottom: 36px;
    border-bottom: 1px solid $border;
    text-align: center;
    @include m {
      margin: 0;
      padding: 40px 16px;
      border: none;
    }
  }
  &__person &__preview {
    width: 104px;
    height: 104px;
    margin: 0 auto 8px;
  }
  &__person &__title {
    margin: 0;
  }
  &__person &__text {
    margin-bottom: 28px;
  }
  &__socials {
    justify-content: center;
  }
  &__reply {
    margin: 0 -28px -28px;
    border-radius: 0 0 4px 4px;
    @include m {
      margin: 0;
      background: white;
      border-radius: 0;
    }
  }
  &__data {
    @include m {
      margin: 0 -12px;
    }
  }

  &_about {
    border: 1px solid $border;
    border-radius: 8px;
    @include m {
      background: white;
      border: none;
    }
  }
  &_about &__container {
    @include m {
      padding: 0;
    }
  }
  &_about &__inner {
    max-width: 614px;
    margin: 0 auto;
    padding: 48px 24px 24px;
    @include m {
      padding: 0;
    }
  }
  &_about &__person {
    @include m {
      padding: 16px;
    }
  }
  &_about &__actions {
    margin: 0;
  }
}
