.calendar {
  border: 1px solid $border;
  border-radius: 8px;
  @include t {
    overflow: auto;
  }
  @include m {
    background: white;
  }
  &__container {
    min-width: 834px;
    @include m {
      min-width: 100%;
    }
  }
  &__head {
    padding: 13px 0 12px;
    background: $bg-lighter;
    border-radius: 6px 6px 0 0;
    color: $gray;
    text-align: center;
    @include m {
      background: $border;
    }
  }
  &__body &__cell {
    box-shadow: 0 1px 0 0 $border, 1px 0 0 0 $border;
    cursor: pointer;
    &:nth-child(n + 29) {
      box-shadow: 1px 0 0 0 $border;
    }
    &:nth-child(35) {
      box-shadow: none;
    }
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
  }
  &__cell {
    padding: 8px;
    flex: 1;
    &_sm {
      flex: 0 0 54px;
    }
  }
  &__cell.other-month &__value {
    color: $gray;
  }
  &__value {
    font-size: 12px;
    font-weight: 700;
    text-align: right;
  }
  &__event {
    padding: 8px 12px;
    border-radius: 6px;
    &.custom {
      background-color: $bg;
      background-image: linear-gradient(
        -45deg,
        $border 25%,
        transparent 25%,
        transparent 50%,
        $border 50%,
        $border 75%,
        transparent 75%,
        transparent
      );
      background-size: 40px 40px;
      color: $gray;
    }
  }
  &__name {
    font-weight: 700;
  }
  &__input {
    width: 100%;
    height: 20px;
    background: none;
    @include m {
      display: none;
    }
  }
  &__hour {
    //box-shadow: 0 1px 0 0 $border, 0 -1px 0 0 $border;
    &:first-child {
      box-shadow: 0 1px 0 0 $border;
    }
  }
  &__hour &__row {
    &:not(:last-child) {
      box-shadow: 0 1px 0 0 $border;
    }
  }
  &__hour &__cell {
    position: relative;
    min-height: 34px;
  }
  &__cover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 4px;
    //overflow: auto;
    z-index: 2;
    &:before {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      right: 0;
      bottom: 1px;
      background: white;
      z-index: -2;
    }

    &:after {
      content: '';
      position: absolute;
      height: 4px;
      width: calc(100%);
      top: 0px;
      left: 0px;
      background: #c8d6e5;
      z-index: -1;
    }

    &.last::before {
      right: 1px;
    }
  }
  &__cover &__event {
    min-height: 100%;
  }

  &_month &__body &__cell {
    flex: 0 0 14.2857%;
  }
  &_month &__event {
    min-height: 82px;
    margin-top: 12px;
    @include m {
      width: 8px;
      min-height: 8px;
      margin: 12px auto 0;
      padding: 0;
      border-radius: 50%;
      font-size: 0;
      &.green {
        background: $green;
      }
      &.red {
        background: $red;
      }
      &.blue {
        background: $blue;
      }
      &.yellow {
        background: $yellow;
      }
      &.purple {
        background: $purple;
      }
      &.gray {
        background: $gray;
      }
      &.turquoise {
        background: $turquoise;
      }
    }
  }
}
