.badge {
  display: flex;
  align-items: center;
  min-width: 24px;
  padding: 0 6px;
  background: $blue-light;
  border-radius: 12px;
  font-weight: 700;
  color: $blue;
  text-align: center;
  line-height: 24px;
  &.red {
    background: rgba($red, 0.1);
    color: $red;
  }
  &.green {
    background: rgba($green, 0.1);
    color: $green;
  }
  &.yellow {
    background: rgba($yellow, 0.1);
    color: $yellow;
  }
  &.purple {
    background: rgba($purple, 0.1);
    color: $green;
  }
}

.mini-badge {
  display: inline-flex;
  align-items: center;
  padding: 0.3125em 0.5em;
  font-size: 100%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.3125rem;
  transition: all 0.2s ease-in-out;
  background: rgba($gray, 0.1);
  color: $gray;

  &_soft-success {
    color: #00c9a7;
    background-color: rgba(0, 201, 167, 0.1);
  }

  &_soft-warning {
    color: #f5ca99;
    background-color: rgba(245, 202, 153, 0.1);
    color: #ec9a3c !important;
  }

  &__legend-indicator {
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    background-color: #bdc5d1;
    border-radius: 50%;
    margin-right: 0.4375rem;
    background: rgba($gray, 1);
  }

  &.blue {
    background: rgba($blue, 0.1);
    color: $blue;
  }
  &.blue &__legend-indicator {
    background: rgba($blue, 1) !important;
  }

  &.red {
    background: rgba($red, 0.1);
    color: $red;
  }
  &.red &__legend-indicator {
    background: rgba($red, 1) !important;
  }

  &.yellow {
    background: rgba($yellow, 0.1);
    color: $yellow;
  }
  &.yellow &__legend-indicator {
    background: rgba($yellow, 1) !important;
  }

  &.green {
    background: rgba($green, 0.1);
    background-color: rgba(0, 201, 167, 0.1);
    color: $green;
  }
  &.green &__legend-indicator {
    background: rgba($green, 1) !important;
  }

  &.purple {
    background: rgba($purple, 0.1);
    background-color: rgba(0, 201, 167, 0.1);
    color: $purple;
  }
  &.purple &__legend-indicator {
    background: rgba($purple, 1) !important;
  }

  .bg-success {
    background-color: #00c9a7 !important;
  }

  .bg-warning {
    background-color: #ec9a3c !important;
  }
}

.container__title {
  .mini-badge {
    font-size: 80%;
    font-weight: 400;
  }
}
