.field {
  &__label {
    position: relative;
    display: inline-block;
    //margin-bottom: -10px; TODO: Validate why it breaks th ui
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.4px;

    color: $gray;
    z-index: 2;

    &.required::after {
      display: inline-block;
      position: absolute;
      top: -2px;
      left: calc(100% + 2px);
      content: '*';
      color: $blue;
    }
  }
  &__wrap {
    position: relative;
  }

  &__input[disabled='true'],
  &__select[disabled='true'] {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &__input,
  &__select {
    display: block;
    width: 100%;
    height: 54px;
    font-size: 16px;
    padding-right: 24px;
    background-color: transparent;
    border: solid rgba($gray, 0.15);
    border-width: 0 0 2px;
    transition: border-color 0.2s;
    &:focus {
      border-color: $blue;
    }

    &.noicon {
      padding-right: 0;
    }
  }
  &__select {
    border-radius: 0;
    appearance: none;
    color: $gray;
    line-height: 1;
    &.active {
      color: $cl;
    }
  }
  &__icon {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    color: $gray;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }

  &.active &__icon {
    color: $cl;
  }

  &.success &__input {
    border-color: $green;
  }
  &.success &__icon {
    color: $green;
  }

  &.error &__input {
    border-color: $red;
  }
  &.error &__icon {
    color: $red;
  }
}

.no-margin {
  .field {
    margin-bottom: 0px;
  }
}

.field__select.dense {
  display: block;
  width: 100%;
  height: 40px;
  font-size: 12px;
  padding-right: 24px;
  background-color: transparent;
  border: solid #f0f0f3;
  border-width: 0 0 2px;
  transition: border-color 0.2s;

  .selection {
    font-size: 12px !important;
  }
}

.dense {
  .field__select {
    display: block;
    width: 100%;
    height: 40px;
    font-size: 12px;
    padding-right: 24px;
    background-color: transparent;
    border: solid rgba($gray, 0.8);
    border-width: 0 0 2px;
    transition: border-color 0.2s;
  }

  .select2-selection__rendered {
    font-size: 14px !important;
  }
}

.select2-selection__rendered {
  .la {
    color: $blue !important;
  }
}

.select2-icon-option {
  display: flex;
  align-items: center;

  .la {
    color: $gray;
    padding-right: 4px;
  }
}

.select2-results__option--highlighted {
  .la {
    color: #fff;
  }
}
