.chat {
  display: flex;
  height: calc(100vh - 160px);
  flex-direction: column;
  &__top {
    display: flex;
    margin-bottom: 40px;
    align-items: center;
    justify-content: space-between;
  }
  &__close {
    display: none;
    @include t {
      display: block;
      margin-right: 24px;
    }
  }
  &__search {
    position: relative;
    padding-left: 23px;
    flex: 0 0 150px;
    @include m {
      display: none;
    }
    .la {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &__input {
    display: block;
    width: 100%;
    height: 18px;
    font-size: 12px;
    &::placeholder {
      text-transform: uppercase;
    }
  }
  &__head {
    margin-bottom: 20px;
  }
  &__text {
    margin-bottom: 20px;
    color: $gray;
  }
  &__body {
    padding: 20px 0;
    flex: 1 1 auto;
    border: solid $border;
    border-width: 1px 0;
    overflow: auto;
  }
  &__msg {
    display: flex;
    margin-bottom: 6px;
    align-items: flex-end;
  }
  &__msg_reply {
    flex-direction: row-reverse;
    justify-content: flex-start;
  }
  &__msg_reply &__time {
    text-align: right;
  }
  &__msg_reply &__comment {
    background: $blue;
    color: white;
  }
  &__user {
    margin-bottom: 26px;
    flex: 0 0 24px;
  }
  &__pic {
    border-radius: 50%;
  }
  &__wrap {
    max-width: 70%;
    margin: 0 8px;
  }
  &__comment {
    margin-bottom: 4px;
    padding: 8px 16px 7px;
    background: $bg;
    border-radius: 18px 18px 18px 0;
    @include m {
      background: white;
    }
  }
  &__time {
    min-height: 18px;
    font-size: 12px;
    font-weight: 700;
    color: $gray;
    text-transform: uppercase;
  }
  &__typing {
    display: flex;
    padding: 7px 3px 6px;
    align-items: center;
    &:before,
    span,
    &:after {
      width: 8px;
      height: 8px;
      background: $gray;
      border-radius: 50%;
      opacity: 0.2;
      animation: typing 1.5s linear infinite;
    }
    &:before,
    &:after {
      content: '';
    }
    span {
      margin: 0 6px;
      animation-delay: 0.5s;
    }
    &:after {
      animation-delay: 1s;
    }
  }
  &__reply {
    margin-top: 24px;
  }

  &_invoice {
    position: absolute;
    top: 0;
    right: 0;
    width: 425px;
    height: 100%;
    padding-top: 98px;
    background: $bg-lighter;
    border-radius: 0 8px 8px 0;
  }
  &_invoice &__top {
    margin-bottom: 30px;
    padding: 0 30px;
  }
  &_invoice &__body {
    padding: 30px;
  }
  &_invoice &__reply {
    margin: 0;
    padding: 0;
    border-radius: 0 0 8px;
  }

  &_file &__top {
    margin-bottom: 20px;
  }

  &_notify {
    height: auto;
  }
  &_notify &__top {
    margin-bottom: 4px;
  }
  &_notify &__body {
    border: none;
  }
  &_notify &__comment {
    @include m {
      background: $bg-light;
    }
  }
  &_notify &__reply {
    margin: 0 -28px -28px;
    border-radius: 0 0 11px 11px;
    @include m {
      margin: 0 -14px -14px;
      background: $bg;
    }
  }

  &_messages {
    @include t {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 24px;
      background: white;
      z-index: 999;
    }
    @include m {
      padding: 16px;
    }
  }
  &_messages &__top {
    @include m {
      margin-bottom: 16px;
    }
  }
}

@keyframes typing {
  0%,
  66.66%,
  100% {
    opacity: 0.2;
  }
  33.33% {
    opacity: 1;
  }
}
