.action {
  display: inline-flex;
  min-width: 36px;
  height: 36px;
  padding: 0 9px;
  background: #e9e9f1;
  border: 1px solid transparent;
  border-radius: 6px;
  font-weight: 700;
  color: $gray;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  vertical-align: middle;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    color: $cl;
  }
  .la {
    font-size: 16px;
  }

  &_title {
    padding: 0 18px;
    background: none;
    &.active {
      background: white;
      border-color: $border;
      color: $cl;
    }
  }

  &_icon_before {
    padding: 0 17px 0 11px;
    .la {
      margin-right: 5px;
    }
  }
  &_icon_after {
    padding: 0 11px 0 17px;
    .la {
      margin-left: 5px;
    }
  }

  &_stroke {
    background: white;
    border-color: $border;
  }
}

.action-group {
  display: flex;
  margin: 0 -6px -6px 0;
  flex-wrap: wrap;
  .action {
    margin: 0 6px 6px 0;
  }
}

.action-group_grow {
  @include m {
    margin: 0 -3px;
  }
  .action {
    margin: 0 3px;
    flex: 1;
  }
}
