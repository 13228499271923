.data {
  margin: 0 -24px -24px;
  @include m {
    margin: 0;
  }
  &__head {
    padding: 12px 24px;
    background: $bg-lighter;
    border: 1px solid transparent;
    color: $gray;
    @include m {
      display: none;
    }
  }
  &__row {
    display: flex;
    align-items: center;
  }
  &__cell {
    padding: 0 8px;
    flex: 1;
    @include m {
      padding: 0 14px 0 0;
      flex: auto;
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 24px;
      @include m {
        padding-right: 14px;
      }
    }
    &_xl {
      flex: 3;
      @include m {
        flex: 1 1 auto;
      }
    }
    &_lg {
      flex: 2;
      @include m {
        flex: 1 1 auto;
      }
    }
    &_md {
      flex: 1.5;
      @include m {
        flex: 1 1 auto;
      }
    }
    &_sm {
      flex: 0.75;
    }
    &_xs {
      flex: 0.33333;
    }
    &_members,
    &_tags {
      min-width: 172px;
      flex: 1.3;

      &.mini {
        min-width: 90px;
        flex: 1;
      }
    }
    &_action {
      flex: 0 0 68px;
      @include m {
        flex: 0 0 50px;
      }
    }
    &_actions {
      flex: 0.75;
      justify-content: flex-end;
      align-items: center;
      display: flex;
      min-width: 120px;
      @include m {
      }

      > *:not(:last-child) {
        margin-right: 15px;
      }
    }
    &_toggle {
      max-width: 72px;
      flex: 0 0 72px;
      @include m {
        max-width: 54px;
        flex: 0 0 54px;
      }
    }
    &_time {
      flex: 0 0 84px;
    }
  }
  &__filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: color 0.2s;
    cursor: pointer;
    &:hover {
      color: $cl;
    }
  }
  &__body {
    padding: 24px;
    @include m {
      padding: 0;
    }
  }
  &__item {
    padding: 20px 0;
    border: 1px solid $border;
    border-radius: 12px;
    @include m {
      padding: 12px 0;
      background: white;
      border: none;
      border-radius: 8px;
    }
    &:not(:last-child) {
      margin-bottom: 8px;
      @include m {
        margin-bottom: 4px;
      }
    }
  }
  &__icon {
    color: $gray;
  }
  &__effect {
    margin-right: 20px;
  }
  &__main {
    display: flex;
    //padding-left: 24px;
    align-items: center;
    @include m {
      padding-left: 14px;
    }
  }
  &__cell:first-child &__main {
    padding-left: 24px;
  }
  &__preview {
    display: flex;
    position: relative;
    width: 48px;
    height: 48px;
    margin-right: 20px;
    background: #f5f5fa;
    border-radius: 6px;
    font-size: 22px;
    align-items: center;
    justify-content: center;
    flex: 0 0 48px;
    @include m {
      margin-right: 16px;
    }
    &_file {
      flex: 0 0 40px;
    }

    &_icon {
      padding: 6px;
    }

    &.data__preview-icon {
      background: none;
      width: 90px !important;
      height: 90px !important;
      margin: 0 auto 0 auto;
      flex: 0 0 90px !important;
      //padding: 10px;
      color: #121f40;

      &.list {
        width: 50px !important;
        height: 50px !important;
        flex: 0 0 50px !important;
        margin: 0;
        margin-right: 20px;
      }

      .icon-fill {
        fill: currentColor;
      }

      .icon-stroke {
        stroke: currentColor;
      }

      &.inactive {
        opacity: 0.3;
      }
    }
  }
  &__preview_file &__pic {
    max-width: 20px;
    max-height: 20px;
    border-radius: 0;
  }
  &__pic {
    border-radius: 6px;
  }
  &__label {
    color: $gray;
    font-weight: 300;
  }
  &__content {
    font-size: 16px;
    &_between {
      display: flex;
      justify-content: space-between;
    }
    .la {
      margin-right: 4px;
      color: $gray;

      &.is-preview {
        position: relative;
        top: 1px;
        margin-right: 8px;
        color: $gray;
      }
    }
  }
  &__stars {
    .la {
      font-size: 16px;
      color: $blue;
      &.active {
        opacity: 0.5;
        color: $gray;
      }
    }
  }
  &__corner {
    display: flex;
    position: absolute;
    top: 16px;
    right: 16px;
    min-width: 36px;
    min-height: 36px;
    justify-content: center;
    align-items: center;
    z-index: 2;

    & > *:not(:last-child) {
      margin-right: 12px;
    }

    &_left {
      left: 16px;
      right: auto;
    }
  }
  &__corner &__tag {
    min-width: 0;
    padding: 7px 12px 8px;
  }
  &__foot {
    display: flex;
    width: calc(100% + 32px);
    margin: 12px -16px -16px;
    border-top: 1px solid $border;
  }
  &__box {
    padding: 16px 16px 18px;
    justify-content: center;
    flex: 0 0 50%;
    @include m {
      padding: 14px;
    }
    &:first-child {
      border-right: 1px solid $border;
    }

    &.box_full:first-child {
      border: none;
      flex: 1;
    }
  }
  &__progress {
    margin: 9px 0 4px;
  }

  &_list {
    overflow: auto;
  }

  &_info {
    position: relative;
    top: -24px;
  }

  &_list &__container {
    min-width: 884px;
    width: 100%;
    @include m {
      min-width: 0;
    }
  }

  &_grid &__head &__cell {
    &:last-child {
      padding-right: 0;
    }
  }
  &_grid &__body {
    display: flex;
    margin: 0 -8px -16px;
    text-align: center;
    flex-wrap: wrap;
    @include m {
      display: block;
      margin: 0;
    }
  }
  &_grid &__item {
    position: relative;
    min-width: 240px;
    margin: 0 8px 16px;
    padding: 24px 16px 16px;
    flex: 1 1 calc(25% - 16px);
    @include m {
      margin: 0 0 4px;
    }
  }
  &_grid &__item &__row {
    flex-direction: column;
  }
  &_grid &__item &__cell {
    width: 100%;
    padding: 12px 0;
  }
  &_grid &__main {
    padding: 0;
    flex-direction: column;
  }
  &_grid &__preview {
    width: 64px;
    height: 64px;
    margin: 0 auto 12px;
    flex: 0 0 64px;
    &_large {
      width: auto;
      height: auto;
      margin: -36px -16px 24px;
    }
  }
  &_grid &__preview_large &__pic {
    width: 100%;
    border-radius: 11px 11px 0 0;
  }
  &_grid &__preview_file {
    width: 52px;
  }
  &_grid &__members,
  &_grid &__tags {
    justify-content: center;
  }

  &_messages &__body {
    padding-top: 0;
  }

  &.no-header &__body {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.data {
  .data__content {
    a {
      color: $cl;

      i {
        color: $blue;
        transform: translate3d(0, 0, 0);
        transition: transform ease-out 250ms;
      }

      &:hover i {
        will-change: transform;
        transform: translate3d(5px, 0, 0);
      }
    }
  }

  &.data_dense .data__content {
    font-size: 14px;
  }

  &.data_dense .data__filter {
    font-size: 0.75em;
  }

  &.data_dense .tag {
    min-width: 80px;
    padding: 4px 5px;
    font-size: 0.85em;
  }

  .data__cell_action {
    //flex-basis: auto;

    button:not(:last-child) {
      margin-right: 6px;
    }
  }
}

.panel__head.panel__head_nav {
  margin-bottom: 50px !important;
}
.panel__head.panel__head_filters {
  margin-bottom: 36px !important;
}

.data__cell_xl {
  max-width: 40%;
}

.data__items {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.data__items-loading {
  opacity: 0.3;
  transition: opacity 150ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.data__body {
  position: relative;
  z-index: 1;
  min-height: 150px;

  etrk-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
}

.search-filters-group {
  flex-grow: 1;
  position: relative;
  top: -25px;

  ::placeholder,
  .select2-container--default
    .select2-selection--single
    .select2-selection__placeholder {
    //color: #d8d8e6 !important;
    font-weight: 400;
    font-size: 14px;
  }

  .field__input,
  .field__select,
  .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    font-size: 14px !important;
  }
}

.data_drid {
  .data__item {
    position: relative;
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: scale(1.01, 1.01);
    }

    &:hover::after {
      opacity: 1;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      opacity: 0;
      border-radius: 12px;
      box-shadow: 0 6px 12px rgba(140, 152, 164, 0.25) !important;
      transition: opacity 0.3s ease-in-out;
      background: none;
    }
  }
  .data__corner button {
    background-color: #fbfbfd;
  }
}
