.btn {
  display: inline-flex;
  height: 46px;
  padding: 0 28px;
  background: $blue;
  border: 1px solid $blue;
  border-radius: 8px;
  font-weight: 700;
  color: white;
  transition: all 0.2s;
  align-items: center;
  justify-content: center;
  vertical-align: middle;

  &.is-btn-danger {
    border-color: $red;
    background: $red;

    &:hover {
      border-color: $red;
      background: $red;
    }
  }

  &:hover {
    background: $btn-hover;
    border-color: $btn-hover;
  }
  &[disabled] {
    background: $bg;
    border-color: $bg;
    color: $gray;
    cursor: not-allowed;
  }
  &_light {
    background: $blue-light;
    border-color: $blue-light;
    color: $blue;
    &:hover {
      background: darken($blue-light, 5);
      border-color: darken($blue-light, 5);
    }
    &[disabled] {
      background: $bg;
      border-color: $bg;
      color: $gray;
    }
  }

  &_tab {
    position: relative;
    background: transparent;
    border-color: transparent;
    color: $gray;
    padding: 0 8px !important;

    &:hover:not([disabled]) {
      background: transparent;
      border-color: transparent;
      color: $blue;
    }
    &[disabled] {
      background: transparent;
      border-color: transparent;
      opacity: 0.4;
    }

    &::after {
      position: absolute;
      top: 100%;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 3px;
      content: '';
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      background: $blue;
      opacity: 0;
    }

    &_active {
      color: $blue;
      font-weight: 700;
      &::after {
        opacity: 1;
      }
    }
  }
  &_stroke {
    background: transparent;
    border-color: $blue;
    color: $blue;
    &:hover {
      background: transparent;
      border-color: $blue;
      color: $cl;
    }
    &[disabled] {
      background: transparent;
      border-color: $btn-outline;
      color: $gray;
    }
    &.is-btn-danger {
      background: transparent;
      border-color: $red;
      color: $red;

      &:hover {
        background: transparent;
      }
    }

    &.is-btn-success {
      background: transparent;
      border-color: $green;
      color: $cl;
    }
  }

  &_rounded {
    border-radius: 25px;
  }

  &.is-small {
    height: 40px;
    padding: 0 18px;
    font-weight: 500;
  }

  &.is-mini {
    height: 35px;
    padding: 0 14px;
    font-size: 0.9em;
    font-weight: 500;
  }

  &_icon {
    padding: 0 18px;
    .la,
    .icofont {
      margin-right: 8px;
    }
  }
  &_only_icon {
    padding: 0 13px;
    font-size: 0;
    .la,
    .icofont {
      font-size: 18px;
    }
  }
}

.btn-group {
  display: flex;
  margin: 0 -6px -6px 0;
  flex-wrap: wrap;
  .btn {
    height: 40px;
    margin: 0 6px 6px 0;
    &.active {
      background: $blue;
      border-color: $blue;
      color: white;
    }
  }
}

.btn-group_tabs {
  @include m {
    margin: 0 -3px;
    flex: 1 1 auto;
  }
  .btn {
    @include m {
      min-width: calc(33.33% - 6px);
      height: 36px;
      margin: 0 3px;
      padding: 0 9px;
      background: none;
      border-color: transparent;
      border-radius: 6px;
      color: $gray;
      flex: 1;
      &.active {
        background: white;
        border-color: transparent;
        color: $cl;
      }
    }
  }
  .la,
  .icofont {
    @include m {
      display: none;
    }
  }
}
